import React from 'react';
import '../styles/Main.css'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    //Link
  } from "react-router-dom";

import Banner from './Banner';
import Footer from './Footer';
import MainForm from './MainForm';
import VmVc from './VmVc';
import Register from './Register'
import Login from './Login'
import Admin from './admin/Admin'

const Main = () => {
  return (
    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' element={<MainPage/>}/>
      <Route exact path='/VmVc' element={<VmVc/>}/>
      <Route exact path='/register' element={<RegisterPage/>}/>
      <Route exact path='/login' element={<LoginPage/>}/>
      <Route exact path='/admin' element={<AdminPage/>}/>
    </Routes>
  );
}

function RegisterPage(){
  return <div className='cnpa-page'>
        <Banner/>
        <Register/>
        <Footer/>
    </div>
}

function LoginPage(){
  return <div className='cnpa-page'>
        <Banner/>
        <Login/>
        <Footer/>
    </div>
}

function AdminPage(){
  return <div className='cnpa-page'>
        <Admin/>
        <Footer/>
    </div>
}

function MainPage(){
    return <div className='cnpa-page'>
        <Banner/>
        <MainForm/>
        <Footer/>
    </div>
}

export default Main;