import '../styles/Banner.css'

function Banner() {
    const title = 'CNPA Anémomètre'
    return (
        <div className='cnpa-banner'>
            <h1>{title}</h1>
        </div>
    )
}

export default Banner