import React from 'react';
import './styles/AdminTable.css'

class AdminTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            removeTable: [],
            indexTable: {}
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event){
        var propsItems = this.props.items.slice()
        
        const newRemoveTab = this.state.removeTable //Init des nouveaux tableaux state
        const newDataTab = []
        const newIndexTab = this.state.indexTable

        if(event.target.checked) { //Si check, on enlève de removeTab la valeur
            var index = newRemoveTab.indexOf(event.target.value);
            if (index > -1) {
                newRemoveTab.splice(index, 1);
            }
            newIndexTab[event.target.value] = true
        } else { //Si uncheck on ajoute dans le tableau la key à retirer
            newRemoveTab.push(event.target.value)
            newIndexTab[event.target.value] = false
        }

        this.setState({removeTable: newRemoveTab})
        propsItems.forEach(elemNewDataTab =>{
            var row = {}
            Object.entries(elemNewDataTab).forEach(([key, value]) =>{
                row[key] = value
                newRemoveTab.forEach(elemNewRemoveTab => {
                    if(key == elemNewRemoveTab){
                        delete row[key]
                    }
                })
            })
            newDataTab.push(row)
        })
        this.setState({
            data: newDataTab
        })
    }

    componentDidMount(){
        this.setState({
            data: this.props.items,
            indexTable: {"id" : true, "email" : true, "userIdentifier" : true, "roles" : true}
        })
    }

    render() {
        return (
            <div className="admin-cnpa-table-content">
                <form>
                    <label htmlFor="table-id"><input type="checkbox" id="table-id" value="id" defaultChecked onClick={this.handleClick}/>id</label>
                    <label htmlFor="table-email"><input type="checkbox" id="table-email" value="email" defaultChecked onClick={this.handleClick} />email</label>
                    <label htmlFor="table-userIdentifier"><input type="checkbox" id="table-userIdentifier" value="userIdentifier" defaultChecked onClick={this.handleClick} />userIdentifier</label>
                    <label htmlFor="table-roles"><input type="checkbox" id="table-roles" value="roles" defaultChecked onClick={this.handleClick}/>roles</label>
                </form>
                <table className="admin-cnpa-table-value">
                    <tbody>
                        <tr>
                            {Object.entries(this.state.indexTable).map(([i, v], index) => {
                                if(v == true)
                                    return <th>{i}</th>
                                return null
                            })}
                        </tr>
                        {this.state.data.map((value, index) => {
                            return <tr key={index}>
                                {Object.entries(value).map((property) => {
                                    return <td>{property['1']}</td>
                                })}
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default AdminTable