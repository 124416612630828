import React from 'react';
import './styles/AdminJson.css'

class AdminJson extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const myJsonStr = JSON.stringify(this.props.items); //Json to string nos items
        return (
            <div className="admin-cnpa-json-content">
                {myJsonStr}
            </div>
        );
    }
}

export default AdminJson