import React from 'react'
import Cookies from 'universal-cookie';
import SousMenu from './SousMenu';
import '../styles/MainForm.css'

class MainForm extends React.Component{

    constructor(props) {
        super(props)

        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            dataGraphe: [],
            jour: 0,
            mois: 0,
            annee: 0,
            defaultJsonToken: null,
            jsonToken: null,
            isLogged: null,
            isAdmin: null,
            errorcode: null,
            errormessage: "",
            requestFailed: null
        };
    
        this.handleChangeDay = this.handleChangeDay.bind(this);
        this.handleChangeMonth = this.handleChangeMonth.bind(this);
        this.handleChangeYear = this.handleChangeYear.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLast24 = this.handleLast24.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
    }

    handleChangeDay(event) {
        this.setState({jour: event.target.value});
    }

    handleChangeMonth(event) {
        this.setState({mois: event.target.value});
    }

    handleChangeYear(event) {
        this.setState({annee: event.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if(this.state.jsonToken == null){
            alert('Il faut se connecter pour utiliser cette fonctionnalité')
        } else {
            const newData = []
            var apiUrl = 'https://api.anemometre.link/api/cnpa/vent?' //On crée l'URL
            const headers = { 'Content-Type': 'application/json', 'token': this.state.jsonToken } //On donne les attributs du header
            
            if (this.state.jour != 0) { //Si le jour est défini alors on l'ajoute à l'URL pour récupérer les bonnes données
                apiUrl = apiUrl.concat("jour=", this.state.jour,  "&")
            }

            if (this.state.mois != 0) { //Pareil avec le mois
                apiUrl = apiUrl.concat("mois=", this.state.mois,  "&")
            }
            
            if (this.state.annee != 0) { //Pareil avec l'année
                apiUrl = apiUrl.concat("annee=", this.state.annee,  "&")
            }

            fetch(apiUrl, {headers} ) //On interroge avec le header sinon ça ne fonctionne pas
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then(
                (result) => {
                    this.setState({  //Si réponse : On modifie l'attribut isLoaded et items de la classe
                        isLoaded: true,
                        items: result
                    })
                    result.forEach(element => newData.push({ a: parseInt(element.time), b: parseInt(element.v_m0), c: parseInt(element.v_c0) })) //Et on ajoute notre modifie notre attribut data pour le content Graph
                }
            )
            .catch((error) => {
                console.log('error: ' + error);
                this.setState({ requestFailed: true });
            });
            this.setState({dataGraphe: newData}) //On donne à notre state data, les nouvelles valeurs
        }
    }

    handleLast24(){
        const apiUrl = 'https://api.anemometre.link/api/cnpa/vent/last24';
        const headers = { 'Content-Type': 'application/json', 'token': this.state.defaultJsonToken }
        const newData = []
        //Maintenant qu'on a notre token, on va interroger l'api
        fetch(apiUrl, {headers} ) //On interroge avec le header sinon ça ne fonctionne pas
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else return response.json();
        })
        .then(
            (result) => { //Si réponse
                result.forEach(element => {
                    newData.push({ a: parseInt(element.time), b: parseInt(element.v_m0), c: parseInt(element.v_c0) }) //On ajoute pour modifier notre attribut data pour le content Graph
                })
                this.setState({ //On modifie l'attribut isLoaded et items de la classe
                    isLoaded: true,
                    items: result
                })
            }
        )
        .catch((error) => {
            console.log('error: ' + error);
            this.setState({ requestFailed: true });
        });
        this.setState({dataGraphe: newData}) //On donne à notre state data, les nouvelles valeurs
    }

    handleDownload()
    {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(this.state.items)
          )}`;
        const link = document.createElement("a")
        link.href = jsonString
        link.download = "data.json"
        link.click()
    }

    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    showYear(){ //Montre les années possibles où il y a des données
        const firstDateInData = new Date(1652426540000) //Par défaut le 13/05/2022
        const firstDateInDataYear = firstDateInData.getUTCFullYear()

        const today = new Date()
        const todayYear = today.getUTCFullYear()

        const allPossibleYear = [] //Va récupérer les années possibles
        var cpt = firstDateInDataYear

        while(cpt <= todayYear){ //Si l'année de la première donnée est plus petite ou égale à celle d'aujourd'hui, on ajoute l'année dans les choix
            allPossibleYear.push(cpt)
            cpt = cpt+1
        }

        return (
            <div className='cnpa-form-row'>
                <label className='cnpa-form-row-label' htmlFor="annee">Année</label>
                <select id="annee" name="annee" onChange={this.handleChangeYear}>
                    <option value="0">tout</option>
                    {Object.entries(allPossibleYear).map((value) => { // On affiche le tableau des années possibles
                        return <option value={value[1]}>{value[1]}</option>
                    })}
                </select>
            </div>
        )
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    };

    componentDidMount() {
        document.title = "CNPA Anémomètre"


        const cookies = new Cookies();
        if(cookies.get('jwt')){
            const jwt = cookies.get('jwt')
            this.setState({
                jsonToken: jwt,
                isLogged: true
            })
            const payload = this.parseJwt(jwt)
            payload.roles.forEach(role => {
                if(role=="ROLE_ADMIN"){
                    this.setState({
                        isAdmin: true
                    })
                }
            });
        }

        const today = new Date()
        const todayDay = this.padTo2Digits(today.getDate())
        const todayMonth = this.padTo2Digits(today.getMonth() + 1)
        const todayYear = today.getUTCFullYear()

        const jour = document.getElementById('jour')
        jour.value = todayDay

        const mois = document.getElementById('mois')
        mois.value = todayMonth
        
        const annee = document.getElementById('annee')
        annee.value = todayYear

        this.setState({
            jour: jour.value,
            mois: mois.value,
            annee: annee.value
        })

        if(this.state.jsonToken == null){
            const url = 'https://api.anemometre.link/api/login_check';
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            
            const options = {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                  email : 'clavis',
                  password : 'stella'
                }),
                headers
            };

            const newData = []
            
            fetch(url, options) //On interroge 
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then( //Si réponse
                (result) => {
                    if(result.code){
                        this.setState({ //On récupère le token
                            errorcode: result.code,
                            errormessage: result.message
                        })
                        return
                    }
                    this.setState({ //On récupère le token
                        defaultJsonToken: result.token
                    })
                    const apiUrl = 'https://api.anemometre.link/api/cnpa/vent/last24';
                    const headers = { 'Content-Type': 'application/json', 'token': result.token }
                    //Maintenant qu'on a notre token, on va interroger l'api
                    fetch(apiUrl, {headers} ) //On interroge avec le header sinon ça ne fonctionne pas
                    .then((response) => {
                        if(!response.ok) throw new Error(response.status);
                        else return response.json();
                    })
                    .then(
                        (result) => { //Si réponse
                            result.forEach(element => {
                                newData.push({ a: parseInt(element.time), b: parseInt(element.v_m0), c: parseInt(element.v_c0) }) //On ajoute pour modifier notre attribut data pour le content Graph
                            })
                            this.setState({ //On modifie l'attribut isLoaded et items de la classe
                                isLoaded: true,
                                items: result
                            })
                        }
                    )
                    .catch((error) => {
                        console.log('error: ' + error);
                        this.setState({ requestFailed: true });
                    });
                    this.setState({dataGraphe: newData}) //On donne à notre state data, les nouvelles valeurs
                }
            )
            .catch((error) => {
                console.log('error: ' + error);
                this.setState({ requestFailed: true });
            });
        }
    }
    
    render(){
        return (
            <div className='cnpa-menu'>
                <form className='cnpa-form'>
                    <div className='cnpa-form-rows'>
                        <div className='cnpa-form-row'>
                            <label className='cnpa-form-row-label' htmlFor="jour">Jour</label>
                            <select id="jour" name="jour" onChange={this.handleChangeDay}>
                                <option value="00">tout</option>
                                <option value="01">1</option>
                                <option value="02">2</option>
                                <option value="03">3</option>
                                <option value="04">4</option>
                                <option value="05">5</option>
                                <option value="06">6</option>
                                <option value="07">7</option>
                                <option value="08">8</option>
                                <option value="09">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>
                        </div>
                        <div className='cnpa-form-row'>
                            <label className='cnpa-form-row-label' htmlFor="mois">Mois</label>
                            <select id="mois" name="mois" onChange={this.handleChangeMonth}>
                                <option value="00">tout</option>
                                <option value="01">Janvier</option>
                                <option value="02">Fevrier</option>
                                <option value="03">Mars</option>
                                <option value="04">Avril</option>
                                <option value="05">Mai</option>
                                <option value="06">Juin</option>
                                <option value="07">Juillet</option>
                                <option value="08">Aout</option>
                                <option value="09">Septembre</option>
                                <option value="10">Octobre</option>
                                <option value="11">Novembre</option>
                                <option value="12">Decembre</option>
                            </select>
                        </div>
                        {this.showYear()}
                    </div>
                </form>
                <div className='cnpa-form-buttons'>
                    <button onClick={this.handleSubmit} type="submit">Chercher</button>
                    <button onClick={this.handleLast24}>Afficher les dernières 24 heures</button>
                    <button onClick={this.handleDownload}>Télécharger JSON</button>
                </div>
                <SousMenu jsonToken = {this.state.jsonToken} isLogged = {this.state.isLogged} isAdmin = {this.state.isAdmin} items = {this.state.items} error = {this.state.error} isLoaded = {this.state.isLoaded} dataGraphe = {this.state.dataGraphe} errorcode = {this.state.errorcode} errormessage = {this.state.errormessage} requestFailed = {this.state.requestFailed} />
            </div>
        );
    }
}

export default MainForm