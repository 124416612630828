import React, {useCallback} from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
} from "react-router-dom";
import Cookies from 'universal-cookie';
import '../styles/Login.css'

class Login extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            errorcode: null,
            errormessage: "",
            requestFailed: null,
            jsonToken: null,
            redirect: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    VerifyEmail(){
        const mail = document.getElementById("email").value
        var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
        if(mail.match(mailFormat))
        {
            return true;
        } else {
            alert("Email invalide");
            return false;
        }
    }

    VerifyPassword(){
        const pwd = document.getElementById("password").value
        if(pwd.length >= 6)
        {
            return true;
        } else {
            alert("Votre mot de passe doit contenir au moins 6 caractères");
            return false;
        }
    }

    handleSubmit(event)
    {
        if (this.VerifyEmail() && this.VerifyPassword()){            
            const email = document.getElementById("email").value;
            const pwd = document.getElementById("password").value;

            const url = 'https://api.anemometre.link/api/login_check';
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            
            const options = {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    email : email,
                    password : pwd
                }),
                headers
            };
            
            fetch(url, options) //On interroge 
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then( //Si réponse
                (result) => {
                    if(result.code){
                        this.setState({ //Si erreur
                            errorcode: result.code,
                            errormessage: result.message
                        })
                        return
                    }

                    const cookies = new Cookies();
                    cookies.set('jwt', result.token, { path: '/', maxAge: 1800 });

                    this.setState({ //On récupère le token
                        jsonToken: result.token,
                        redirect: true
                    })
                }
            )
            .catch((error) => {
                console.log('error: ' + error);
                if(error == "Error: 401"){ //Si réponse 401, alors mauvais email ou mot de passe
                    alert("Mauvais email ou mot de passe");
                } else {
                    this.setState({ requestFailed: true });
                }
            });
        }
    }

    

    render(){
        if(this.state.redirect){
            return(<Navigate to="/"/>)
        }
        return (
            <div className="cnpa-login">
                <h1 className='cnpa-login-title'>Se connecter</h1>
                <div className='cnpa-login-inputs'>
                    <div className="cnpa-login-email">
                        <label htmlFor="email">Email : </label>
                        <input type="email" name="email" id="email" required />
                    </div>
                    <div className="cnpa-login-password">
                        <label htmlFor="password">Mot de passe : </label>
                        <input type="password" name="password" id="password" required />
                    </div>
                </div>
                <div className="cnpa-login-buttons">
                    <button type='submit' className='cnpa-login-submit' onClick={this.handleSubmit}>Se connecter</button>
                    <Link to="/"><button className='cnpa-login-retour'>Retour</button></Link>
                </div>
                <div className='cnpa-login-noAccount'>
                    <p>Pas de compte ?</p>
                    <Link to="/register"><button className='cnpa-login-register'>S'enregistrer</button></Link>
                </div>
            </div>
        )
    }
}

export default Login