import React from 'react'
import AdminMenu from './AdminMenu'
import Cookies from 'universal-cookie';
import {
    BrowserRouter as Router,
    Navigate,
} from "react-router-dom";
import './styles/Admin.css'

class Admin extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            defaultJsonToken: null,
            jsonToken: null,
            errorcode: null,
            errormessage: "",
            requestFailed: null,
            nbUsers: null,
            redirect: null
        };
    }
    
    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    };

    componentDidMount(){
        document.title = "[Admin] CNPA Anémomètre"

        const cookies = new Cookies();
        if(cookies.get('jwt')){
            const jwt = cookies.get('jwt')
            this.setState({
                jsonToken: jwt,
                isLogged: true
            })
            const payload = this.parseJwt(jwt)
            var isAdmin = false
            payload.roles.forEach(role => {
                if(role=="ROLE_ADMIN"){
                    isAdmin = true
                }
            })
            if(!isAdmin) {
                alert("Vous n'êtes pas Admin, retour à l'accueil")
                this.setState({ //Retour à l'accueil
                    redirect: true
                })
            }
            const apiUrl = 'https://api.anemometre.link/api/admin';
            const headers = { 'Content-Type': 'application/json', 'token': jwt }
            //Maintenant qu'on a notre token, on va interroger l'api
            fetch(apiUrl, {headers} ) //Check admin dans le back : si réponse alors admin, si erreur/pas de réponse alors pas admin
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then(
                (result) => { //Si réponse
                    var cpt = 0
                    result.forEach(row => {
                        cpt++
                        delete row.password
                    });
                    this.setState({ //On modifie l'attribut isLoaded et items de la classe
                        isLoaded: true,
                        items: result,
                        nbUsers: cpt
                    })
                }
            )
            .catch((error) => {
                console.log('error: ' + error);
                this.setState({ requestFailed: true });
            });
        } else {
            alert("Vous n'êtes pas connecté")
            this.setState({
                redirect: true
            })
        }
    }

    render() {
        if(this.state.redirect){
            return(<Navigate to="/"/>)
        }
        return (
            <div className="admin-cnpa">
                <AdminMenu items = {this.state.items} error = {this.state.error} isLoaded = {this.state.isLoaded} dataGraphe = {this.state.dataGraphe} errorcode = {this.state.errorcode} errormessage = {this.state.errormessage} requestFailed = {this.state.requestFailed} nbUsers = {this.state.nbUsers} />
            </div>
        );
    }
}

export default Admin