import React from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
} from "react-router-dom";
import Cookies from 'universal-cookie';
import '../styles/Register.css'

class Register extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            errorcode: null,
            errormessage: "",
            requestFailed: null,
            redirect : null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    VerifyEmail(){
        const mail = document.getElementById("email").value
        var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
        if(mail.match(mailFormat))
        {
            return true;
        } else {
            alert("Email invalide");
            return false;
        }
    }

    VerifyPassword(){
        const pwd = document.getElementById("password").value
        if(pwd.length >= 6)
        {
            return true;
        } else {
            alert("Votre mot de passe doit contenir au moins 6 caractères");
            return false;
        }
    }

    handleSubmit(event)
    {
        if (this.VerifyEmail() && this.VerifyPassword()){
            const mailValue = document.getElementById("email").value //Input email
            const pwdValue = document.getElementById("password").value //Input password

            const apiUrl = 'https://api.anemometre.link/api/register'; //webservice register
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            
            const options = {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                  email : mailValue,
                  password : pwdValue
                }),
                headers
            };

            fetch(apiUrl, options) //Envoie de la requête
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then(
                (result) => { //Si réponse ok
                    if(result == true){ //Si notre webservice répond true, alors le user est crée
                        alert("Compte créé");
                        const url = 'https://api.anemometre.link/api/login_check';
                        const headers = new Headers();
                        headers.append('Content-Type', 'application/json');
                        
                        fetch(url, options) //On interroge 
                        .then((response) => {
                            if(!response.ok) throw new Error(response.status);
                            else return response.json();
                        })
                        .then( //Si réponse
                            (result) => {
                                if(result.code){
                                    this.setState({ //Si erreur
                                        errorcode: result.code,
                                        errormessage: result.message
                                    })
                                    return
                                }

                                const cookies = new Cookies();
                                cookies.set('jwt', result.token, { path: '/', maxAge: 1800 });

                                this.setState({ //On récupère le token
                                    jsonToken: result.token,
                                    redirect: true
                                })
                            }
                        )
                        .catch((error) => {
                            console.log('error: ' + error);
                            if(error == "Error: 401"){ //Si réponse 401, alors mauvais email ou mot de passe
                                alert("Mauvais email ou mot de passe");
                            } else {
                                this.setState({ requestFailed: true });
                            }
                        });
                    }else
                        alert("Erreur impossible");
                }
            )
            .catch((error) => { //Si erreur / ici on renvoie 409 si l'email est déjà utilisé
                console.log('error: ' + error);
                this.setState({ requestFailed: true });
                if(error == "Error: 409"){
                    alert("Cet Email est déjà utilisé");
                }
            });
        }
    }

    

    render(){
        if(this.state.redirect){
            return(<Navigate to="/"/>)
        }
        return (
            <div className="cnpa-register">
                <h1 className='cnpa-register-title'>S'enregistrer</h1>
                <div className='cnpa-register-inputs'>
                    <div className="cnpa-register-email">
                        <label htmlFor="email">Email : </label>
                        <input type="email" name="email" id="email" required />
                    </div>
                    <div className="cnpa-register-password">
                        <label htmlFor="password">Mot de passe : </label>
                        <input type="password" name="password" id="password" required />
                    </div>
                </div>
                <div className="cnpa-register-buttons">
                    <button className='cnpa-register-submit' onClick={this.handleSubmit}>S'enregistrer</button>
                    <Link to="/"><button className='cnpa-register-retour'>Retour</button></Link>
                </div>
                <div className='cnpa-register-alreadyAccount'>
                    <p>Tu as déjà un compte ?</p>
                    <Link to="/login"><button className='cnpa-register-login'>Se connecter</button></Link>
                </div>
            </div>
        )
    }
}

export default Register