import React from 'react'
import JsonContent from './JsonContent';
import TableContent from './TableContent';
import GraphContent from './GraphContent';
import { BrowserRouter as Router, Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import '../styles/SousMenu.css'

class SousMenu extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            elementvisible: null
        }

        this.seeJson = this.seeJson.bind(this);
        this.seeTable = this.seeTable.bind(this);
        this.seeGraph = this.seeGraph.bind(this);
    }

    seeJson(event){ //Change l'attribut à Json
        this.setState({
            elementvisible: "Json"
        });
    }

    seeTable(event){ //Change l'attribut à Table
        this.setState({
            elementvisible: "Table"
        });
    }

    seeGraph(event){ //Change l'attribut à Graph
        this.setState({ 
            elementvisible: "Graphe"
        })
    }

    showLogout(){ //On affiche le bouton logout
        return <button className='cnpa-sousmenu-logout-button' onClick={this.logout}>Déconnexion</button>
    }

    showAdmin(){ //On affiche le bouton admin
        return <Link to="/admin"><button className='cnpa-sousmenu-admin-button'>Admin</button></Link>
    }

    showRegister(){ //On affiche le bouton register
        return <Link to="/register"><button className='cnpa-sousmenu-register-button'>S'enregistrer</button></Link>
    }

    showLogin(){ //On affiche le bouton login
        return <Link to="/login"><button className='cnpa-sousmenu-login-button'>Se connecter</button></Link>
    }

    logout(){
        const cookies = new Cookies()
        cookies.remove('jwt')
        window.location.reload()
    }

    getComponent(){ //Retourne le composant souhaité en fonction de l'attribut 
        let component;

        if (this.props.requestFailed) {
            component =  <div className='cnpa-message-erreur'>Erreur de requête, vous avez peut-être atteint vos 30 minutes ou vous n'êtes tout simplement pas connecté : Essayez de vous reconnecter !</div>;
        } else if (this.props.error) {
            component =  <div className='cnpa-message-erreur'>Erreur : {this.props.error.message}</div>;
        } else if (!this.props.isLoaded) {
            component =  <div className='cnpa-message-erreur'>Chargement…</div>;
        } else if(Object.keys(this.props.items).length === 0){
            component =  <div className='cnpa-message-erreur'>Il n'y a pas de donnée pour cette date</div>;
        }
        else {
            switch (this.state.elementvisible){
                default:
                    component = <GraphContent items = {this.props.items} error = {this.props.error} isLoaded = {this.props.isLoaded} dataGraphe = {this.props.dataGraphe} />
                    break
                case 'Json' :
                    component = <JsonContent items = {this.props.items} error = {this.props.error} isLoaded = {this.props.isLoaded} />
                    break;
                case 'Table' :
                    component = <TableContent items = {this.props.items} error = {this.props.error} isLoaded = {this.props.isLoaded} />
                    break;
                case 'Graphe' :
                    component = <GraphContent items = {this.props.items} error = {this.props.error} isLoaded = {this.props.isLoaded} dataGraphe = {this.props.dataGraphe} />
                    break;
            }
        }
        return component;
    }

    render(){
        return (
            <div>
                <div className='cnpa-sousmenu'>
                    <div className='cnpa-sousmenu-left'>
                        <button className='cnpa-sousmenu-button' onClick={this.seeGraph}>Graphe</button>
                        <button className='cnpa-sousmenu-button' onClick={this.seeTable}>Table</button>
                        <button className='cnpa-sousmenu-button cnpa-sousmenu-json-button' onClick={this.seeJson}>JSON</button>
                    </div>
                    <div className='cnpa-sousmenu-right'>
                        {(this.props.isLogged)? this.showLogout() : null /*On affiche deconnexion si on est log*/}
                        {(this.props.isAdmin)? this.showAdmin() : null /*On affiche admin si on est admin*/}
                        {(this.props.isLogged)? null : this.showLogin() /*On affiche login si on est pas log*/}
                        {(this.props.isLogged)? null : this.showRegister() /*On affiche register si on est pas log*/}
                    </div>
                </div>
                {this.getComponent()}
            </div>
        )
    }
}

export default SousMenu