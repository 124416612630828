import React from 'react'
import { VictoryChart, VictoryZoomContainer, VictoryLine, VictoryBrushContainer, VictoryAxis, VictoryTheme, VictoryScatter, VictoryLabel, VictoryGroup} from "victory";
import '../styles/GraphContent.css'

class GraphContent extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            zoomDomainVm: null,
            zoomDomainVc: null,
            zoomDomainAll: null
        }
    }

    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    handleZoomVm(domain) {
        this.setState({ zoomDomainVm: domain });
    }

    handleZoomVc(domain) {
        this.setState({ zoomDomainVc: domain });
    }

    handleZoomAll(domain) {
        this.setState({ zoomDomainAll: domain });
    }
    
    render(){
        const dateDebut = new Date()
        dateDebut.setTime(this.props.items[0].time*1000)
        const dateFin = new Date()
        dateFin.setTime(this.props.items[this.props.items.length -1].time*1000)
        var maxVm = 0
        var maxVc = 0
        var max = 0
        this.props.items.forEach(element => {
            if(parseInt(element.v_m0)>maxVm)
                maxVm = parseInt(element.v_m0)
            if(parseInt(element.v_c0)>maxVc)
                maxVc = parseInt(element.v_c0)
        if(maxVm > maxVc)
            max = maxVm
        else
            max = maxVc
        });
        return (
            <div className="cnpa-graph-content">
                <p>{this.padTo2Digits(dateDebut.getDate()) + "/" + this.padTo2Digits(dateDebut.getMonth() + 1) + " " + dateDebut.getHours() + "h" + dateDebut.getMinutes()} --{'>'} {this.padTo2Digits(dateFin.getDate()) + "/" + this.padTo2Digits(dateFin.getMonth() + 1) + " " + dateFin.getHours() + "h" + dateFin.getMinutes()}</p>
                <div className='cnpa-graphs'>
                    <div>
                        <h2>Moyenne et crête</h2>
                        <VictoryChart width={300} height={235} //Création d'un graphe Victory
                        theme={VictoryTheme.material}
                        containerComponent={
                            <VictoryZoomContainer
                            zoomDimension="x"
                            zoomDomain={this.state.zoomDomainAll} //Modifie le zoomdomain
                            onZoomDomainChange={this.handleZoomAll.bind(this)}
                            />
                        }
                        >
                            <VictoryGroup
                            >
                                <VictoryLine //Création des lignes AVANT les points pour que les lignes soient en background des points
                                    style={{
                                        data: { stroke: "tomato" }
                                    }}
                                    data={this.props.dataGraphe}
                                    x="a"
                                    y="b"
                                />
                                <VictoryLine //Création des lignes AVANT les points pour que les lignes soient en background des points
                                    style={{
                                        data: { stroke: "cyan" }
                                    }}
                                    data={this.props.dataGraphe}
                                    x="a"
                                    y="c"
                                />
                                <VictoryScatter //Création d'un composant Scatter de Victory
                                    style={{ data: { fill: "red" }, labels: {fontSize: 8} }}
                                    events={[{
                                        target: "data",
                                        eventHandlers: { //Event sur le clique des points qui affiche la valeur et la date
                                        onClick: () => {
                                            return [
                                            {
                                                target: "data",
                                                mutation: (props) => {
                                                const fill = props.style && props.style.fill;
                                                return fill === "black" ? null : { style: { fill: "black" } }; //Changement de couleur au clique
                                                }
                                            }, {
                                                target: "labels",
                                                mutation: (props) => { //On modifie le label pour afficher la valeur et la date
                                                    const date = new Date(props.datum.a*1000)
                                                    return props.text === props.datum.b + "\n" + this.padTo2Digits(date.getDate()) + "/" + this.padTo2Digits(date.getMonth() + 1) + " " + date.getHours() + "h" + date.getMinutes() ?
                                                    null : { text: props.datum.b + "\n" + this.padTo2Digits(date.getDate()) + "/" + this.padTo2Digits(date.getMonth() + 1) + " " + date.getHours() + "h" + date.getMinutes()} ;
                                                }
                                            }
                                            ];
                                        }
                                        }
                                    }]}
                                    data={this.props.dataGraphe}
                                    x="a"
                                    y="b"
                                    labels=""
                                />
                                <VictoryScatter //Création d'un composant Scatter de Victory
                                    style={{ data: { fill: "blue" }, labels: {fontSize: 8} }}
                                    events={[{
                                        target: "data",
                                        eventHandlers: { //Event sur le clique des points qui affiche la valeur et la date
                                        onClick: () => {
                                            return [
                                            {
                                                target: "data",
                                                mutation: (props) => {
                                                const fill = props.style && props.style.fill;
                                                return fill === "black" ? null : { style: { fill: "black" } }; //Changement de couleur au clique
                                                }
                                            }, {
                                                target: "labels",
                                                mutation: (props) => { //On modifie le label pour afficher la valeur et la date
                                                    const date = new Date(props.datum.a*1000)
                                                    return props.text === props.datum.c + "\n" + this.padTo2Digits(date.getDate()) + "/" + this.padTo2Digits(date.getMonth() + 1) + " " + date.getHours() + "h" + date.getMinutes() ?
                                                    null : { text: props.datum.c + "\n" + this.padTo2Digits(date.getDate()) + "/" + this.padTo2Digits(date.getMonth() + 1) + " " + date.getHours() + "h" + date.getMinutes()} ;
                                                }
                                            }
                                            ];
                                        }
                                        }
                                    }]}
                                    data={this.props.dataGraphe}
                                    x="a"
                                    y="c"
                                    labels=""
                                />
                            </VictoryGroup>
                            <VictoryAxis //Création de l'axe x qui affiche les dates avec un angle
                                domain={[dateDebut.getTime()/1000, dateFin.getTime()/1000]}
                                style={{ grid: { stroke: "none" } }}
                                axisLabelComponent={<VictoryLabel dy={30}/>}
                                tickFormat={(x) => {{ //On modifie l'axe des abscisses si le segment est à moins de 2 jours

                                    if(this.state.zoomDomainAll == null){ //On veut utiliser le zoomdomain pour afficher les bonnes valeurs
                                        if(dateFin.getTime() - dateDebut.getTime() <= 172800000){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(x*1000)
                                            const hour = this.padTo2Digits(date.getHours())
                                            const minute = this.padTo2Digits(date.getMinutes())
                                            return hour + ":" + minute
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            const date = new Date(x*1000)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            return jour + "/" + mois
                                        }
                                    } else { //Si le zoomDomain est défini (lorsqu'on se déplace sur le graph)
                                        if(this.state.zoomDomainAll.x[1] - this.state.zoomDomainAll.x[0] <= 172800){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(x*1000)
                                            const hour = this.padTo2Digits(date.getHours())
                                            const minute = this.padTo2Digits(date.getMinutes())
                                            return hour + ":" + minute
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            const date = new Date(x*1000)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            return jour + "/" + mois
                                        }
                                    }
                                }}}
                                label={(x) => {{
                                    if(this.state.zoomDomainAll == null){ //On veut utiliser le zoomdomain pour afficher les bonnes valeurs
                                        if(dateFin.getTime() - dateDebut.getTime() <= 172800000){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(dateDebut)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            const dateF = new Date(dateFin)
                                            const moisF = this.padTo2Digits(dateF.getMonth() + 1)
                                            const jourF = this.padTo2Digits(dateF.getDate())
                                            return jour + "/" + mois + " - " + jourF + "/" + moisF
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            return ""
                                        }
                                    } else { //Si le zoomDomain est défini (lorsqu'on se déplace sur le graph)
                                        if(this.state.zoomDomainAll.x[1] - this.state.zoomDomainAll.x[0] <= 172800){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(this.state.zoomDomainAll.x[0]*1000)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            const dateF = new Date(this.state.zoomDomainAll.x[1]*1000)
                                            const moisF = this.padTo2Digits(dateF.getMonth() + 1)
                                            const jourF = this.padTo2Digits(dateF.getDate())
                                            return jour + "/" + mois + " - " + jourF + "/" + moisF
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            return ""
                                        }
                                    }
                                }}}
                                tickLabelComponent={<VictoryLabel angle={30}/>}
                            />

                            <VictoryAxis dependentAxis //Création de l'axe y qui affiche les valeurs de l'anémomètre
                                domain={[0, max+max*0.21]} //+max*0.21 de hauteur pour afficher label
                                style={{ grid: { stroke: "none" } }}
                                axisLabelComponent={<VictoryLabel dy={-30} />}
                                label={"km/h"}
                                tickFormat={(y) => y}
                                standalone={false}
                            />
                        </VictoryChart>
                        <VictoryChart
                            padding={{ top: 0, left: 50, right: 50, bottom: 30 }} 
                            width={300} height={50}
                            containerComponent={
                            <VictoryBrushContainer
                                brushDimension="x"
                                brushDomain={this.state.zoomDomainAll}
                                onBrushDomainChange={this.handleZoomAll.bind(this)}
                            />
                            }
                        >
                            <VictoryAxis
                            domain={[dateDebut.getTime()/1000, dateFin.getTime()/1000]}
                            tickFormat={(x) => ""}
                            />
                            <VictoryAxis dependentAxis 
                            domain={[0, max+max*0.21]} //+max*0.21 de hauteur pour afficher label
                            tickFormat={(y) => ""}
                            />
                            
                            <VictoryLine
                            style={{
                                data: { stroke: "tomato" }
                            }}
                            data={this.props.dataGraphe}
                            x="a"
                            y="b"
                            />
                            <VictoryLine
                            style={{
                                data: { stroke: "cyan" }
                            }}
                            data={this.props.dataGraphe}
                            x="a"
                            y="c"
                            />
                        </VictoryChart>
                    </div>
                    <div>
                        <h2>Vitesse moyenne</h2>
                        <VictoryChart width={300} height={235} //Création d'un graphe Victory
                        theme={VictoryTheme.material}
                        containerComponent={
                            <VictoryZoomContainer
                            zoomDimension="x"
                            zoomDomain={this.state.zoomDomainVm} //Pour qu'on puisse naviger avec le deuxième graphe (il modifie le zoomdomain)
                            onZoomDomainChange={this.handleZoomVm.bind(this)}
                            />
                        }
                        >
                            <VictoryLine //Création des lignes AVANT les points pour que les lignes soient en background des points
                                style={{
                                    data: { stroke: "tomato" }
                                }}
                                data={this.props.dataGraphe}
                                x="a"
                                y="b"
                            />
                            <VictoryScatter //Création d'un composant Scatter de Victory
                                style={{ data: { fill: "red" }, labels: {fontSize: 8} }}
                                events={[{
                                    target: "data",
                                    eventHandlers: { //Event sur le clique des points qui affiche la valeur et la date
                                    onClick: () => {
                                        return [
                                        {
                                            target: "data",
                                            mutation: (props) => {
                                            const fill = props.style && props.style.fill;
                                            return fill === "black" ? null : { style: { fill: "black" } }; //Changement de couleur au clique
                                            }
                                        }, {
                                            target: "labels",
                                            mutation: (props) => { //On modifie le label pour afficher la valeur et la date
                                                const date = new Date(props.datum.a*1000)
                                                return props.text === props.datum.b + "\n" + this.padTo2Digits(date.getDate()) + "/" + this.padTo2Digits(date.getMonth() + 1) + " " + date.getHours() + "h" + date.getMinutes() ?
                                                null : { text: props.datum.b + "\n" + this.padTo2Digits(date.getDate()) + "/" + this.padTo2Digits(date.getMonth() + 1) + " " + date.getHours() + "h" + date.getMinutes()} ;
                                            }
                                        }
                                        ];
                                    }
                                    }
                                }]}
                                data={this.props.dataGraphe}
                                x="a"
                                y="b"
                                labels=""
                            />
                            
                            <VictoryAxis //Création de l'axe x qui affiche les dates avec un angle
                                domain={[dateDebut.getTime()/1000, dateFin.getTime()/1000]}
                                style={{ grid: { stroke: "none" } }}
                                axisLabelComponent={<VictoryLabel dy={30}/>}
                                tickFormat={(x) => {{ //On modifie l'axe des abscisses si le segment est à moins de 2 jours
                                    if(this.state.zoomDomainVm == null){ //On veut utiliser le zoomdomain pour afficher les bonnes valeurs
                                        if(dateFin.getTime() - dateDebut.getTime() <= 172800000){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(x*1000)
                                            const hour = this.padTo2Digits(date.getHours())
                                            const minute = this.padTo2Digits(date.getMinutes())
                                            return hour + ":" + minute
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            const date = new Date(x*1000)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            return jour + "/" + mois
                                        }
                                    } else { //Si le zoomDomain est défini (lorsqu'on se déplace sur le graph)
                                        if(this.state.zoomDomainVm.x[1] - this.state.zoomDomainVm.x[0] <= 172800){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(x*1000)
                                            const hour = this.padTo2Digits(date.getHours())
                                            const minute = this.padTo2Digits(date.getMinutes())
                                            return hour + ":" + minute
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            const date = new Date(x*1000)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            return jour + "/" + mois
                                        }
                                    }
                                }}}
                                label={(x) => {{
                                    if(this.state.zoomDomainVm == null){ //On veut utiliser le zoomdomain pour afficher les bonnes valeurs
                                        if(dateFin.getTime() - dateDebut.getTime() <= 172800000){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(dateDebut)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            const dateF = new Date(dateFin)
                                            const moisF = this.padTo2Digits(dateF.getMonth() + 1)
                                            const jourF = this.padTo2Digits(dateF.getDate())
                                            return jour + "/" + mois + " - " + jourF + "/" + moisF
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            return ""
                                        }
                                    } else { //Si le zoomDomain est défini (lorsqu'on se déplace sur le graph)
                                        if(this.state.zoomDomainVm.x[1] - this.state.zoomDomainVm.x[0] <= 172800){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(this.state.zoomDomainVm.x[0]*1000)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            const dateF = new Date(this.state.zoomDomainVm.x[1]*1000)
                                            const moisF = this.padTo2Digits(dateF.getMonth() + 1)
                                            const jourF = this.padTo2Digits(dateF.getDate())
                                            return jour + "/" + mois + " - " + jourF + "/" + moisF
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            return ""
                                        }
                                    }
                                }}}
                                tickLabelComponent={<VictoryLabel angle={30}/>}
                            />

                            <VictoryAxis dependentAxis //Création de l'axe y qui affiche les valeurs de l'anémomètre
                                domain={[0, maxVm+maxVm*0.21]} //+maxVm*0.21 de hauteur pour afficher label
                                style={{ grid: { stroke: "none" } }}
                                axisLabelComponent={<VictoryLabel dy={-30} />}
                                label={"km/h"}
                                tickFormat={(y) => y}
                                standalone={false}
                            />
                        </VictoryChart>
                        <VictoryChart
                            padding={{ top: 0, left: 50, right: 50, bottom: 30 }} 
                            width={300} height={50}
                            containerComponent={
                            <VictoryBrushContainer
                                brushDimension="x"
                                brushDomain={this.state.zoomDomainVm}
                                onBrushDomainChange={this.handleZoomVm.bind(this)}
                            />
                            }
                        >
                            <VictoryAxis
                            domain={[dateDebut.getTime()/1000, dateFin.getTime()/1000]}
                            tickFormat={(x) => ""}
                            />
                            <VictoryAxis dependentAxis 
                            domain={[0, maxVm+maxVm*0.21]} //+maxVm*0.21 de hauteur pour afficher label
                            tickFormat={(y) => ""}
                            />
                            
                            <VictoryLine
                            style={{
                                data: { stroke: "tomato" }
                            }}
                            data={this.props.dataGraphe}
                            x="a"
                            y="b"
                            />
                        </VictoryChart>
                    </div>
                    <div>
                        <h2>Vitesse de crête</h2>
                        <VictoryChart width={300} height={235} //Création d'un graphe Victory
                        theme={VictoryTheme.material}
                        containerComponent={
                            <VictoryZoomContainer
                            zoomDimension="x"
                            zoomDomain={this.state.zoomDomainVc} //Pour qu'on puisse naviger avec le deuxième graphe (il modifie le zoomdomain)
                            onZoomDomainChange={this.handleZoomVc.bind(this)}
                            />
                        }
                        >
                            <VictoryLine //Création des lignes AVANT les points pour que les lignes soient en background des points
                                style={{
                                    data: { stroke: "cyan" }
                                }}
                                data={this.props.dataGraphe}
                                x="a"
                                y="c"
                            />
                            <VictoryScatter //Création d'un composant Scatter de Victory
                                style={{ data: { fill: "blue" }, labels: {fontSize: 8} }}
                                events={[{
                                    target: "data",
                                    eventHandlers: { //Event sur le clique des points qui affiche la valeur et la date
                                    onClick: () => {
                                        return [
                                        {
                                            target: "data",
                                            mutation: (props) => {
                                            const fill = props.style && props.style.fill;
                                            return fill === "black" ? null : { style: { fill: "black" } }; //Changement de couleur au clique
                                            }
                                        }, {
                                            target: "labels",
                                            mutation: (props) => { //On modifie le label pour afficher la valeur et la date
                                                const date = new Date(props.datum.a*1000)
                                                return props.text === props.datum.c + "\n" + this.padTo2Digits(date.getDate()) + "/" + this.padTo2Digits(date.getMonth() + 1) + " " + date.getHours() + "h" + date.getMinutes() ?
                                                null : { text: props.datum.c + "\n" + this.padTo2Digits(date.getDate()) + "/" + this.padTo2Digits(date.getMonth() + 1) + " " + date.getHours() + "h" + date.getMinutes()} ;
                                            }
                                        }
                                        ];
                                    }
                                    }
                                }]}
                                data={this.props.dataGraphe}
                                x="a"
                                y="c"
                                labels=""
                            />
                            <VictoryAxis //Création de l'axe x qui affiche les dates avec un angle
                                domain={[dateDebut.getTime()/1000, dateFin.getTime()/1000]}
                                style={{ grid: { stroke: "none" } }}
                                axisLabelComponent={<VictoryLabel dy={30}/>}
                                tickFormat={(x) => {{ //On modifie l'axe des abscisses si le segment est à moins de 2 jours
                                    if(this.state.zoomDomainVc == null){ //On veut utiliser le zoomdomain pour afficher les bonnes valeurs
                                        if(dateFin.getTime() - dateDebut.getTime() <= 172800000){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(x*1000)
                                            const hour = this.padTo2Digits(date.getHours())
                                            const minute = this.padTo2Digits(date.getMinutes())
                                            return hour + ":" + minute
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            const date = new Date(x*1000)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            return jour + "/" + mois
                                        }
                                    } else { //Si le zoomDomain est défini (lorsqu'on se déplace sur le graph)
                                        if(this.state.zoomDomainVc.x[1] - this.state.zoomDomainVc.x[0] <= 172800){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(x*1000)
                                            const hour = this.padTo2Digits(date.getHours())
                                            const minute = this.padTo2Digits(date.getMinutes())
                                            return hour + ":" + minute
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            const date = new Date(x*1000)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            return jour + "/" + mois
                                        }
                                    }
                                }}}
                                label={(x) => {{
                                    if(this.state.zoomDomainVc == null){ //On veut utiliser le zoomdomain pour afficher les bonnes valeurs
                                        if(dateFin.getTime() - dateDebut.getTime() <= 172800000){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(dateDebut)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            const dateF = new Date(dateFin)
                                            const moisF = this.padTo2Digits(dateF.getMonth() + 1)
                                            const jourF = this.padTo2Digits(dateF.getDate())
                                            return jour + "/" + mois + " - " + jourF + "/" + moisF
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            return ""
                                        }
                                    } else { //Si le zoomDomain est défini (lorsqu'on se déplace sur le graph)
                                        if(this.state.zoomDomainVc.x[1] - this.state.zoomDomainVc.x[0] <= 172800){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(this.state.zoomDomainVc.x[0]*1000)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            const dateF = new Date(this.state.zoomDomainVc.x[1]*1000)
                                            const moisF = this.padTo2Digits(dateF.getMonth() + 1)
                                            const jourF = this.padTo2Digits(dateF.getDate())
                                            return jour + "/" + mois + " - " + jourF + "/" + moisF
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            return ""
                                        }
                                    }
                                }}}
                                tickLabelComponent={<VictoryLabel angle={30}/>}
                            />

                            <VictoryAxis dependentAxis //Création de l'axe y qui affiche les valeurs de l'anémomètre
                                domain={[0, maxVc+maxVc*0.21]} //+maxVc*0.21 de hauteur pour afficher label
                                style={{ grid: { stroke: "none" } }}
                                axisLabelComponent={<VictoryLabel dy={-30} />}
                                label={"km/h"}
                                tickFormat={(y) => y}
                                standalone={false}
                            />
                        </VictoryChart>
                        <VictoryChart
                            padding={{ top: 0, left: 50, right: 50, bottom: 30 }} 
                            width={300} height={50}
                            containerComponent={
                            <VictoryBrushContainer
                                brushDimension="x"
                                brushDomain={this.state.zoomDomainVc}
                                onBrushDomainChange={this.handleZoomVc.bind(this)}
                            />
                            }
                        >
                            <VictoryAxis
                            domain={[dateDebut.getTime()/1000, dateFin.getTime()/1000]}
                            tickFormat={(x) => ""}
                            />
                            <VictoryAxis dependentAxis 
                            domain={[0, maxVc+maxVc*0.21]} //+maxVc*0.21 de hauteur pour afficher label
                            tickFormat={(y) => ""}
                            />
                            
                            <VictoryLine
                            style={{
                                data: { stroke: "cyan" }
                            }}
                            data={this.props.dataGraphe}
                            x="a"
                            y="c"
                            />
                        </VictoryChart>
                    </div>
                </div>
            </div>
        )
    }
}

export default GraphContent