import React from 'react'
import '../styles/TableContent.css'

class TableContent extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            removeTable: [],
            indexTable: {}
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
    }

    handleClick(event){
        var propsItems = this.props.items.slice()
        
        const newRemoveTab = this.state.removeTable //Init des nouveaux tableaux state
        const newDataTab = []
        const newIndexTab = this.state.indexTable

        if(event.target.checked) { //Si check, on enlève de removeTab la valeur
            var index = newRemoveTab.indexOf(event.target.value);
            if (index > -1) {
                newRemoveTab.splice(index, 1);
            }
            newIndexTab[event.target.value] = true
        } else { //Si uncheck on ajoute dans le tableau la key à retirer
            newRemoveTab.push(event.target.value)
            newIndexTab[event.target.value] = false
        }

        this.setState({removeTable: newRemoveTab})
        propsItems.forEach(elemNewDataTab =>{
            var row = {}
            Object.entries(elemNewDataTab).forEach(([key, value]) =>{
                row[key] = value
                newRemoveTab.forEach(elemNewRemoveTab => {
                    if(key == elemNewRemoveTab){
                        delete row[key]
                    }
                })
            })
            newDataTab.push(row)
        })
        this.setState({
            data: newDataTab
        })
    }

    handleDownload()
    {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(this.state.data)
          )}`;
        const link = document.createElement("a")
        link.href = jsonString
        link.download = "data.json"
        link.click()
    }

    componentDidMount(){
        this.setState({
            data: this.props.items,
            indexTable: {"id" : true, "time" : true, "v_m3" : true, "d_a3" : true, "v_m2" : true, "d_a2" : true, "v_m1" : true, "d_a1" : true, "v_m0" : true, "d_a0" : true, "v_c1" : true, "a_c1" : true, "v_c0" : true, "a_c0" : true, "temperature" : true, "temperature_time" : true}
        })
    }

    render() {
        if(!this.state.data){
            return <div className='cnpa-message-erreur'>Erreur : Il n'y a pas de donnée</div>
        } else {
            return (
                <div className="cnpa-table-content">
                    <form>
                        <label htmlFor="table-id"><input type="checkbox" id="table-id" value="id" defaultChecked onClick={this.handleClick}/>id</label>
                        <label htmlFor="table-time"><input type="checkbox" id="table-time" value="time" defaultChecked onClick={this.handleClick} />time</label>
                        <label htmlFor="table-v_m3"><input type="checkbox" id="table-v_m3" value="v_m3" defaultChecked onClick={this.handleClick} />vM3</label>
                        <label htmlFor="table-d_a3"><input type="checkbox" id="table-d_a3" value="d_a3" defaultChecked onClick={this.handleClick}/>dA3</label>
                        <label htmlFor="table-v_m2"><input type="checkbox" id="table-v_m2" value="v_m2" defaultChecked onClick={this.handleClick} />vM2</label>
                        <label htmlFor="table-d_a2"><input type="checkbox" id="table-d_a2" value="d_a2" defaultChecked onClick={this.handleClick} />dA2</label>
                        <label htmlFor="table-v_m1"><input type="checkbox" id="table-v_m1" value="v_m1" defaultChecked onClick={this.handleClick}/>vM1</label>
                        <label htmlFor="table-d_a1"><input type="checkbox" id="table-d_a1" value="d_a1" defaultChecked onClick={this.handleClick} />dA1</label>
                        <label htmlFor="table-v_m0"><input type="checkbox" id="table-v_m0" value="v_m0" defaultChecked onClick={this.handleClick} />vM0</label>
                        <label htmlFor="table-d_a0"><input type="checkbox" id="table-d_a0" value="d_a0" defaultChecked onClick={this.handleClick}/>dA0</label>
                        <label htmlFor="table-v_c1"><input type="checkbox" id="table-v_c1" value="v_c1" defaultChecked onClick={this.handleClick} />vC1</label>
                        <label htmlFor="table-a_c1"><input type="checkbox" id="table-a_c1" value="a_c1" defaultChecked onClick={this.handleClick} />aC1</label>
                        <label htmlFor="table-v_c0"><input type="checkbox" id="table-v_c0" value="v_c0" defaultChecked onClick={this.handleClick} />vC0</label>
                        <label htmlFor="table-a_c0"><input type="checkbox" id="table-a_c0" value="a_c0" defaultChecked onClick={this.handleClick} />aC0</label>
                        <label htmlFor="table-temperature"><input type="checkbox" id="table-temperature" value="temperature" defaultChecked onClick={this.handleClick} />temperature</label>
                        <label htmlFor="table-temperature_time"><input type="checkbox" id="table-temperature_time" value="temperature_time" defaultChecked onClick={this.handleClick} />temperatureTime</label>
                    </form>
                    <button onClick={this.handleDownload}>Télécharger JSON de la table</button>
                    <table className="cnpa-table-value">
                        <tbody>
                            <tr>
                                {Object.entries(this.state.indexTable).map(([i, v], index) => {
                                    if(v == true)
                                        return <th>{i}</th>
                                    return null
                                })}
                            </tr>
                            {this.state.data.map((value, index) => {
                                return <tr key={index}>
                                    {Object.entries(value).map((property) => {
                                        return <td>{property['1']}</td>
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            )
        }
    }
}

export default TableContent