import React from 'react'
import AdminTable from './AdminTable'
import AdminJson from './AdminJson'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
} from "react-router-dom";
import './styles/AdminMenu.css'

class AdminSousMenu extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            title: null,
            elementvisible: null
        };

        this.seeJson = this.seeJson.bind(this);
        this.seeTable = this.seeTable.bind(this);
    }

    seeJson(event){ //Change l'attribut à Json
        this.setState({
            elementvisible: "Json"
        });
    }

    seeTable(event){ //Change l'attribut à Table
        this.setState({
            elementvisible: "Table"
        });
    }

    getComponent(){ //Retourne le composant souhaité en fonction de l'attribut 
        let component;

        if (this.props.requestFailed) {
            component =  <div className='admin-cnpa-message-erreur'>Erreur de requête</div>;
        } else if (this.props.error) {
            component =  <div className='admin-cnpa-message-erreur'>Erreur : {this.props.error.message}</div>;
        } else if (!this.props.isLoaded) {
            component =  <div className='admin-cnpa-message-erreur'>Chargement…</div>;
        } else if(Object.keys(this.props.items).length === 0){
            component =  <div className='admin-cnpa-message-erreur'>Il n'y a pas de donnée</div>;
        }
        else {
            switch (this.state.elementvisible){
                default:
                    component = <AdminTable items = {this.props.items} />
                    break
                case 'Table' :
                    component = <AdminTable items = {this.props.items} />
                    break;
                case 'Json' :
                    component = <AdminJson items = {this.props.items} />
                    break;
            }
        }
        return component;
    }

    componentDidMount(){
        this.setState({
            title:"Affichage admin de CNPA Anémomètre"
        })
    }

    render() {
        return (
            <div>
                <div className='admin-cnpa-banner'>
                    <h1>{this.state.title}</h1>
                </div>
                <div className="admin-cnpa-sousmenu">
                    <button className='admin-cnpa-sousmenu-button' onClick={this.seeTable}>Table</button>
                    <button className='admin-cnpa-sousmenu-button' onClick={this.seeJson}>JSON</button>
                    <button className='admin-nb-users'>Nb Users : {this.props.nbUsers}</button>
                </div>
                {this.getComponent()}
                <div className='admin-cnpa-retour-div'>
                    <Link to="/"><button className='admin-cnpa-retour-button'>Retour</button></Link>
                </div>
            </div>
        );
    }
}

export default AdminSousMenu