import '../styles/Footer.css'

function Footer() {
    const madeBy = 'CNPA Anémomètre par '
    const name = 'Eixa6'
    const cnpa = 'Club Nautique de Marennes'
    return (
        <div className='cnpa-footer'>
            <p><a href='http://club.quomodo.com/cnpa17'>{cnpa}</a> - <a href='https://www.eixa6.com/'>{madeBy}{name}</a></p>
        </div>
    )
}

export default Footer