import React from 'react'
import { VictoryChart, VictoryZoomContainer, VictoryLine, VictoryBrushContainer, VictoryAxis, VictoryTheme, VictoryScatter, VictoryLabel, VictoryGroup} from "victory";
import '../styles/VmVc.css'

class VmVc extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            zoomDomainVmVc: null,
            error: null,
            isLoaded: false,
            items: [],
            dataGraphe: []
        }
    }

    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    handleZoomVmVc(domain) {
        this.setState({ zoomDomainVmVc: domain });
    }

    componentDidMount() {
        document.title = "CNPA Anémomètre"

        if(this.state.token == null){
            const url = 'https://api.anemometre.link/api/login_check';

            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            
            const options = {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                  email : 'clavis',
                  password : 'stella'
                }),
                headers
              };

            fetch(url, options) //On interroge 
            .then(response => response.json())
            .then( //Si réponse
                (result) => {
                    this.setState({ //On récupère le token
                        jsonToken: result.token
                    })
                    //Et on fait notre nouvelle requête avec le token
                    const apiUrl = 'https://api.anemometre.link/api/cnpa/vent/last24';
                    const headers = { 'Content-Type': 'application/json', 'token': result.token }
                    const newData = []
                    //Maintenant qu'on a notre token, on va interroger l'api
                    fetch(apiUrl, {headers} ) //On interroge avec le header sinon ça ne fonctionne pas
                        .then(response => response.json())
                        .then(
                            (result) => { //Si réponse
                                result.forEach(element => {
                                    newData.push({ a: parseInt(element.time), b: parseInt(element.v_m0), c: parseInt(element.v_c0) }) //On ajoute pour modifier notre attribut data pour le content Graph
                                })
                                this.setState({ //On modifie l'attribut isLoaded et items de la classe
                                    isLoaded: true,
                                    items: result
                                })
                            }
                        )
                        .catch((err) => { //Si erreur, on l'affiche dans la console
                            console.log(err.message);
                    })
                    this.setState({dataGraphe: newData}) //On donne à notre state data, les nouvelles valeurs
                }
            )
            .catch((err) => { //Si erreur, on l'affiche dans la console
                console.log(err.message);
            })
        }
    }
    
    render(){
        if (this.state.error) {
            return <div>Erreur : {this.state.error.message}</div>;
        } else if (!this.state.isLoaded) {
            return <div>Chargement…</div>;
        } else if(Object.keys(this.state.items).length === 0){
            return <div>Il n'y a pas de données pour cette date</div>;
        }else {
            const dateDebut = new Date()
            dateDebut.setTime(this.state.items[0].time*1000)
            const dateFin = new Date()
            dateFin.setTime(this.state.items[this.state.items.length -1].time*1000)
            var maxVm = 0
            var maxVc = 0
            var max = 0
            this.state.items.forEach(element => {
                if(parseInt(element.v_m0)>maxVm)
                    maxVm = parseInt(element.v_m0)
                if(parseInt(element.v_c0)>maxVc)
                    maxVc = parseInt(element.v_c0)
            if(maxVm > maxVc)
                max = maxVm
            else
                max = maxVc
            });
            return (
                <div className="cnpa-vmvc-content">
                    <div className='cnpa-vmvc'>
                        <VictoryChart width={300} height={235} //Création d'un graphe Victory
                        theme={VictoryTheme.material}
                        containerComponent={
                            <VictoryZoomContainer
                            zoomDimension="x"
                            zoomDomain={this.state.zoomDomainVmVc} //Modifie le zoomdomain
                            onZoomDomainChange={this.handleZoomVmVc.bind(this)}
                            />
                        }
                        >
                            <VictoryGroup
                            >
                                <VictoryLine //Création des lignes AVANT les points pour que les lignes soient en background des points
                                    style={{
                                        data: { stroke: "tomato" }
                                    }}
                                    data={this.state.dataGraphe}
                                    x="a"
                                    y="b"
                                />
                                <VictoryLine //Création des lignes AVANT les points pour que les lignes soient en background des points
                                    style={{
                                        data: { stroke: "cyan" }
                                    }}
                                    data={this.state.dataGraphe}
                                    x="a"
                                    y="c"
                                />
                            </VictoryGroup>
                            <VictoryAxis //Création de l'axe x qui affiche les dates avec un angle
                                domain={[dateDebut.getTime()/1000, dateFin.getTime()/1000]}
                                style={{ grid: { stroke: "none" } }}
                                axisLabelComponent={<VictoryLabel dy={30}/>}
                                tickFormat={(x) => {{ //On modifie l'axe des abscisses si le segment est à moins de 2 jours

                                    if(this.state.zoomDomainAll == null){ //On veut utiliser le zoomdomain pour afficher les bonnes valeurs
                                        if(dateFin.getTime() - dateDebut.getTime() <= 172800000){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(x*1000)
                                            const hour = this.padTo2Digits(date.getHours())
                                            const minute = this.padTo2Digits(date.getMinutes())
                                            return hour + ":" + minute
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            const date = new Date(x*1000)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            return jour + "/" + mois
                                        }
                                    } else { //Si le zoomDomain est défini (lorsqu'on se déplace sur le graph)
                                        if(this.state.zoomDomainAll.x[1] - this.state.zoomDomainAll.x[0] <= 172800){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(x*1000)
                                            const hour = this.padTo2Digits(date.getHours())
                                            const minute = this.padTo2Digits(date.getMinutes())
                                            return hour + ":" + minute
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            const date = new Date(x*1000)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            return jour + "/" + mois
                                        }
                                    }
                                }}}
                                label={(x) => {{
                                    if(this.state.zoomDomainAll == null){ //On veut utiliser le zoomdomain pour afficher les bonnes valeurs
                                        if(dateFin.getTime() - dateDebut.getTime() <= 172800000){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(dateDebut)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            const dateF = new Date(dateFin)
                                            const moisF = this.padTo2Digits(dateF.getMonth() + 1)
                                            const jourF = this.padTo2Digits(dateF.getDate())
                                            return jour + "/" + mois + " - " + jourF + "/" + moisF
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            return ""
                                        }
                                    } else { //Si le zoomDomain est défini (lorsqu'on se déplace sur le graph)
                                        if(this.state.zoomDomainAll.x[1] - this.state.zoomDomainAll.x[0] <= 172800){ //Si les extrêmes sont à moins de 2 jours, alors on affiche au format hour:minute
                                            const date = new Date(this.state.zoomDomainAll.x[0]*1000)
                                            const mois = this.padTo2Digits(date.getMonth() + 1)
                                            const jour = this.padTo2Digits(date.getDate())
                                            const dateF = new Date(this.state.zoomDomainAll.x[1]*1000)
                                            const moisF = this.padTo2Digits(dateF.getMonth() + 1)
                                            const jourF = this.padTo2Digits(dateF.getDate())
                                            return jour + "/" + mois + " - " + jourF + "/" + moisF
                                        } else { //Si les extrêmes sont à plus de 2 jours, alors on affiche au format jour/mois
                                            return ""
                                        }
                                    }
                                }}}
                                tickLabelComponent={<VictoryLabel angle={30}/>}
                            />

                            <VictoryAxis dependentAxis //Création de l'axe y qui affiche les valeurs de l'anémomètre
                                domain={[0, max+max*0.21]} //+max*0.21 de hauteur pour afficher label
                                style={{ grid: { stroke: "none" } }}
                                axisLabelComponent={<VictoryLabel dy={-30} />}
                                label={"km/h"}
                                tickFormat={(y) => y}
                                standalone={false}
                            />
                        </VictoryChart>
                    </div>
                </div>
            )
        }
    }
}

export default VmVc