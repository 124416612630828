import React from 'react';
import '../styles/JsonContent.css'

class JsonContent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const myJsonStr = JSON.stringify(this.props.items); //Json to string nos items
        return (
            <div className="cnpa-json-content">
                {myJsonStr}
            </div>
        );
    }
}

export default JsonContent